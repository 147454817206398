import React from 'react';
import './hamburgerMenu.scss';
import { Link, StaticQuery, graphql } from 'gatsby';
import { IContactData } from '../../typings';
import SocialLink from '../links/socialLink';

interface IHamburgerInternalLinkProps {
  page: string
  name: string
}

const HamburgerInternalLink: React.FunctionComponent<IHamburgerInternalLinkProps> = props => {
  const { page, name } = props;
  return (
    <Link to={page} activeClassName='active'>
      <p>{name}</p>
    </Link>
  );
}

interface IHamburgerProps {
  isOpen: boolean;
  onClose: () => any;
}

export const HamburgerMenu: React.FunctionComponent<IHamburgerProps> = props => {
  const { isOpen } = props;

  return (
    <StaticQuery query={graphql`
          query HamburgerQuery {
            contact: markdownRemark( name: { eq: "contact" } ){
              facebook
              instagram
              email
            }
          }`}
      render={(data: { contact: IContactData }) => {
        const { instagram, facebook, email } = data.contact;
        return (
          <div className='hamburger-menu' data-opened={isOpen} onClick={e => e.stopPropagation()}>
            {/* <Link to='/' activeStyle={{ pointerEvents: 'none' }} className='home-link'>
              <p>DIALOGUE</p>
            </Link> */}
            <HamburgerInternalLink name='Home' page='/' />
            {/* <HamburgerInternalLink name='Club Nights' page='/clubnight' /> */}
            <HamburgerInternalLink name='Radio Shows' page='/radioshows' />
            <div className='socials'>
              <SocialLink service='instagram' href={instagram} />
              <SocialLink service='facebook' href={facebook} />
              <SocialLink service='mail' href={email} />
            </div>
          </div>
        )
      }}
    />
  )
}