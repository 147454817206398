import * as React from 'react'
import { HamburgerMenu } from './hamburgerMenu';
import './hamburgerButton.scss';

interface IHamburgerButtonProps {
}

export const HamburgerButton: React.FunctionComponent<IHamburgerButtonProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  let htmlelem: HTMLElement = undefined;

  const toggleOpen = (override?: boolean) => {
    setIsOpen(!!override ? override : !isOpen);
  }

  const setHTML = () => {
    if (!htmlelem)
      htmlelem = document.getElementsByTagName('html')[0]
  }

  React.useEffect(() => {
    setHTML();
    if (!isOpen) {
      htmlelem.style.overflowY = 'auto';
    }
    else {
      htmlelem.style.overflowY = 'hidden';
    }
  }, [isOpen])

  return (
    <div className="hamburger-button-wrapper">
      <div className='hamburger-button' onClick={e => toggleOpen()} data-is-open={isOpen}>
        <div className='hamburger-button-inner' />
      </div>
      <HamburgerMenu isOpen={isOpen} onClose={() => toggleOpen(false)} />
    </div>
  )
}