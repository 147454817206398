import React from 'react';
import './backToTop.scss';

interface IProps {
  headerProgress: number
}

export const BackToTop: React.FunctionComponent<IProps> = (props: IProps) =>
  <div className="back-to-top-wrapper"
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    data-usable={props.headerProgress >= 0.5}
    style={{ opacity: props.headerProgress * 2 - 1 }}
  >
    <div className='back-to-top' />
  </div>