import React from 'react'
import { Link } from 'gatsby'

import { HamburgerButton } from './hamburgerButton';
import { BackToTop } from '../navigation/backToTop';

import './header.scss';

interface IHeaderProps {
  className?: string;
  isIndex?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = props => {
  const { isIndex } = props;

  const [headerProgress, setHeaderProgress] = React.useState<number>(isIndex ? 0 : 1);
  const [isAtTop, setIsAtTop] = React.useState<boolean>(true);

  const onscroll = () => {
    const scroll = window.scrollY;
    if (scroll <= 50){
      setIsAtTop(true);
    }
    else{
      setIsAtTop(false);
    }
    if (isIndex) {
      if (scroll < 270) {
        window.requestAnimationFrame(() => setHeaderProgress(scroll * 0.00375));
      }
      else if (headerProgress !== 1) {
        setHeaderProgress(1);
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', onscroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onscroll);
    }
  }, [])

  return (
    <div className='header' data-is-index={!!isIndex} data-at-top={isAtTop}>
      <HamburgerButton />
      <Link to='/'>
        <div
          className="header-logo"
          style={{
            transform:
              isIndex
                ?
                `translate(0, calc(${(1 - headerProgress) * 50}vh - ${(1 - headerProgress) * 50}% ) ) 
                  scale(${1.3 - 0.3 * headerProgress})`
                :
                'none'
          }}>
          <p>DIALOGUE</p>
        </div>
      </Link>
      <BackToTop headerProgress={headerProgress} />
    </div>
  )
}

export default Header;